.body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.section-header {
    padding: 10px;
    font-size: 24px;
}

.gallery-container {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.gallery {
    padding: 10px;
}