.hero-image {
    width: 100%;
    height: 100%;
    text-align: center;
}

.hero {
    width: 100%;
    height: 600px;
    position: relative;
}

.hero-text {
    width: 100%;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 1px 1px 10px black;
    font-size: 64px;
}