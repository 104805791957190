.header {
    position: sticky;
    box-shadow: 1px 2px #888888;
    padding: 10px;
    height: 40px;
    top: 0%;
    color: white;
    background: #5ab3bf;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.header-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 32px;
}

.menu {
    display: flex;
    justify-content: right;
    align-items: center;
}

.MuiSvgIcon-root {
    height: 48px;
    width: 48px;
}