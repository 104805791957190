.gallery {
    position: relative;
    cursor: pointer;
}

.gallery-image {
    width: 400px;
    height: 400px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    display: block;
    transition: .5 ease;
    backface-visibility: hidden;
}

.gallery-text {
    position: absolute;
    top: 40%;
    left: 20%;
    background-color: gray;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

.gallery-modal {
    position: fixed;
    z-index: 2;
    left: 20%;
    top: 10%;
    width: 60%;
    height: 80%;
    background-color: #eee;
    overflow: hidden;
}

.gallery-modal-content {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    margin: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gallery-modal-container {
    overflow: hidden;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 425px) {
    .gallery-arrow-right {
        right: 1%;
    }

    .gallery-modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .gallery-modal-container {
        max-width: 100%;
    }

    .gallery-container {
        -webkit-tap-highlight-color: transparent;
    }
}

.close-button {
    color: white;
    text-shadow: 1px 2px 5px black;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    z-index: 2;
}

.arrow {
    position: absolute;
    border: 1px solid white;
    border-width: 0 3px 3px 0;

    display: inline-block;
    padding: 10px;
    margin: 0 10px;
}

.gallery-arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

@media only screen and (min-width: 325px) {
    .gallery-arrow-right {
        right: 0;
    }
}

.gallery-arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

@media only screen and (max-width: 400px) {
    .gallery-image {
        width: 300px;
        height: 300px;
    }

    .overlay,
    .gallery-text {
        position: unset;
    }
}

@media (min-width:1025px) {
    .overlay {
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 40%;
        left: 33%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    .gallery:hover .gallery-image {
        opacity: 0.3;
    }

    .gallery:hover .overlay {
        opacity: 1;
    }
}